<template>
  <v-card>
    <v-app-bar
      dense
      :color="status.color"
    >
      <v-toolbar-title>Статус</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{on, attrs}">
          <v-btn
            v-bind="attrs"
            class="mr-1"
            small
            icon
            outlined
            v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(s, index) in orderStatusList()" :key="index" @click="setStatusAction(s)">
            <v-list-item-title>{{ s.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
    <v-card-text class="d-flex justify-space-between">
      <span class="text-h4">{{ status.text }}</span>
    </v-card-text>
  </v-card>
</template>
<script>

import { orderStatusList } from '@/utils/OrderStatus'

export default {
  name: 'OrderStatusCard',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
    }
  },
  computed: {
    status() {
      const value = this.order?.status ?? ''

      return orderStatusList().find((o) => o.value === value) ?? { value: null, text: null, icon: null, color: null }
    }
  },
  methods: {
    orderStatusList,
    setStatusAction(value) {
      this.$emit('onSelect', value)
    }
  }
}
</script>
